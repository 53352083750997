import React, { Fragment, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { contentfulClient } from '../../../contentfulClient.js'
import { useTranslation } from 'react-i18next';

import Slider from "react-slick";

export const slickConfig = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    responsive: [
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 993,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 580,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
};

function WorkList(props) {
    const [postData, setPostData] = useState([]);

    const currentLang = props.currentLang;

    useEffect(() => {
        const fetchData = async () => {
            const res = await contentfulClient.getEntries({
                content_type: 'works',
                locale: currentLang,
                order: '-fields.dateWorked',
                limit: 10,
            });
            setPostData(res.items);
        };
        fetchData();
    }, [currentLang]);

    const { t } = useTranslation();

    if (postData.length > 0) {
        return(
            <Fragment>

                <div className="portfolio-area ptb--120 bg_color--1" id="portfolio">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title text-left mb--30">
                                    <span className="subtitle">My Recent Works</span>
                                    <h2 className="title">Works</h2>
                                    <p className="description">{t('home.works.description')}</p>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                                    <Link className="btn-transparent rn-btn-dark" to="/works"><span className="text">View All Works</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper portfolio-sacousel-inner mb--55">
                        <div className="portfolio-slick-activation mt--30 mt_sm--30">
                            <Slider {...slickConfig}>
                                {postData.map((post , index) => {
                                    if (post.fields.title && post.fields.slug) {
                                        return (
                                            <div className="im_portfolio" key={index}>
                                                <div className="thumbnail_inner">
                                                    <div className="thumbnail">
                                                        <Link to={'/works/' + post.fields.slug}>
                                                            <img src={post.fields.thumb && post.fields.thumb.fields.file ? post.fields.thumb.fields.file.url + "?fit=thumb&w=635&h=635" : '/assets/img/post_placeholder/landing/works.jpg'} alt="Work Thumbnail" />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <div className="inner">
                                                        <div className="portfolio_heading">
                                                            <div className="category_list">
                                                                <Link to={'/works/' + post.fields.slug}>{post.fields.tag && post.fields.tag.length > 0 ? post.fields.tag[0].fields.name : 'My Featured Work'}</Link>
                                                            </div>
                                                            <h4 className="title"><Link to={'/works/' + post.fields.slug}>{post.fields.titleShort ? post.fields.titleShort : post.fields.title}</Link></h4>
                                                        </div>
                                                        <div className="portfolio_hover">
                                                            <p>{post.fields.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Link className="transparent_link" to={'/works/' + post.fields.slug}></Link>
                                            </div>
                                        )
                                    } else {
                                        return null;
                                    }
                                })}
                            </Slider>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    } else {
        return(
            <Fragment>

                <div className="portfolio-area ptb--120 bg_color--1" id="portfolio">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title text-left mb--30">
                                    <span className="subtitle">My Recent Works</span>
                                    <h2 className="title">Works</h2>
                                    <p className="description">{t('home.works.description')}</p>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                                    <Link className="btn-transparent rn-btn-dark" to="/works"><span className="text">View All Works</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}
export default WorkList;