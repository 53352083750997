import React, { Component } from "react";
// import { Link } from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import PageHelmet from "../../component/common/Helmet";
import Breadcrumb from "../../elements/common/Breadcrumb";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

import { withTranslation } from 'react-i18next';


import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

import WorksListContent from "../../component/body/list/worksList";

class WorksList extends Component{

    render(){
        const { i18n } = this.props;

        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 50) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        const thisYear = new Date().getFullYear();
        const currentYear = this.props.match.params.year ? Number(this.props.match.params.year) : thisYear;

        var yearList = [currentYear];
        var yearLen = 2;
        for (let i = 0; i < 2; i++) {
            if ((yearList[0] + 1) > thisYear) {
                yearLen++;
            } else {
                yearList.unshift(yearList[0] + 1);
            }
        };
        for (let i = 0; i < yearLen; i++) {
            if ((yearList[yearList.length - 1] - 1) >= 2013) {
                yearList.push(yearList[yearList.length - 1] - 1);
            }
        };



        return(
            <React.Fragment>

                <Header />

                <PageHelmet pageTitle={'Works in ' + currentYear} />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Works in ' + currentYear} parent={'Works'} />
                {/* End Breadcrump Area */}


                {/* Start Content Area */}
                <div className="rn-blog-area ptb--10 bg_color--1" id="listContents">
                    <div className="container">

                        {/* Start Pagination Area */}
                        <div className="row mt--60">
                            <div className="col-lg-12">
                                <div className="rn-pagination text-center">
                                    <ul className="page-list">
                                        {currentYear < thisYear ?
                                            <li><HashLink to={"/works/sort/" + (currentYear + 1) + "#listContents"}><FaAngleLeft /></HashLink></li>
                                            :
                                            <li><HashLink to="#listContents"><FaAngleLeft /></HashLink></li>
                                            }
                                        {yearList.map((year, index) => {
                                            if (year === currentYear) {
                                                return (
                                                    <li key={index} className="active"><HashLink to={"/works/sort/" + year + "#listContents"}>{year}</HashLink></li>
                                                )
                                            } else {
                                                return (
                                                    <li key={index}><HashLink to={"/works/sort/" + year + "#listContents"}>{year}</HashLink></li>
                                                )
                                            }
                                        })}
                                        {currentYear > 2013 ?
                                            <li><HashLink to={"/works/sort/" + (currentYear - 1) + "#listContents"}><FaAngleRight /></HashLink></li>
                                        : null}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* End Pagination Area */}

                        {/* Start List Area */}
                        <WorksListContent currentLang={i18n.language} currentYear={currentYear} />
                        {/* End List Area */}


                        {/* Start Pagination Area */}
                        <div className="row ptb--50">
                            <div className="col-lg-12">
                                <div className="rn-pagination text-center">
                                    <ul className="page-list">
                                        {currentYear < thisYear ?
                                            <li><HashLink to={"/works/sort/" + (currentYear + 1) + "#listContents"}><FaAngleLeft /></HashLink></li>
                                            :
                                            <li><HashLink to="#listContents"><FaAngleLeft /></HashLink></li>
                                            }
                                        {yearList.map((year, index) => {
                                            if (year === currentYear) {
                                                return (
                                                    <li key={index} className="active"><HashLink to={"/works/sort/" + year + "#listContents"}>{year}</HashLink></li>
                                                )
                                            } else {
                                                return (
                                                    <li key={index}><HashLink to={"/works/sort/" + year + "#listContents"}>{year}</HashLink></li>
                                                )
                                            }
                                        })}
                                        {currentYear > 2013 ?
                                            <li><HashLink to={"/works/sort/" + (currentYear - 1) + "#listContents"}><FaAngleRight /></HashLink></li>
                                        : null}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* End Pagination Area */}

                    </div>
                </div>
                {/* End Content Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default withTranslation('translation')(WorksList);
