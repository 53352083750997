import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp, FiCheck } from "react-icons/fi";
import { Link } from 'react-router-dom';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import AboutTab from "../elements/tab/AboutTab";
import Helmet from "../component/common/Helmet";

import { useTranslation } from 'react-i18next';

const AboutPage = () => {
    const { t, i18n } = useTranslation();

    window.addEventListener('scroll', function() {
        var value = window.scrollY;
        if (value > 50) {
            document.querySelector('.header--fixed').classList.add('sticky')
        }else{
            document.querySelector('.header--fixed').classList.remove('sticky')
        }
    });

    return (
        <div>
            <Helmet pageTitle={t("about.name")} lang={i18n.language} pageThumb="https://www.386.jp/assets/img/portfolio/header.jpg" />

            <Header />

            {/* Start Slider Area   */}
            <div id="home" className="fix">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image"  style={{backgroundImage: 'url(/assets/img/portfolio/header.jpg'}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className='inner text-left'>
                                        <span className="theme-color font-700">{t("about.title")}</span>
                                        <h1 className="title theme-gradient">{t("about.name")}</h1>
                                        <div className="slide-btn mt--20"><Link className="btn-default" to='/portfolio'>{t("about.toPortfolio")}</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */}

            {/* Start About Area */}
            <div id="about" className="fix">
                <div className="about-area ptb--120 bg_color--5">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/img/portfolio/profile.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <span className="subtitle">About me</span>
                                            <h2 className="title">{t("about.name")}</h2>
                                            <p className="description mt_dec--20" style={{whiteSpace: 'pre-line'}}>{t("about.bio")}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <AboutTab tabStyle="tab-style--1"  currentLang={i18n.language} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* End About Area */}

            <Footer />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
        </div>
    )
}

export default AboutPage;
