import * as contentful from 'contentful';

var ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
var HOST_NAME = 'cdn.contentful.com';

if (process.env.REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN) {
    ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN;
    HOST_NAME = 'preview.contentful.com';
}

export const contentfulClient = contentful.createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: ACCESS_TOKEN,
    host: HOST_NAME
})