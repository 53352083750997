import React, { Fragment, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Helmet from "../../../component/common/Helmet";
import { contentfulClient } from '../../../contentfulClient.js'
import { useTranslation } from 'react-i18next';

import Error404Content from '../../../component/body/error/404';

import moment from 'moment'
import ModalVideo from 'react-modal-video';
import { FiCheck, FiGlobe, FiUser, FiUsers } from "react-icons/fi";
import { FaGlobeAmericas, FaTwitter, FaInstagram, FaGithub } from "react-icons/fa";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import ReactMarkdown from 'react-markdown';


function WorksContent(props) {
    const parentThis = props.this;

    const [postData, setPostData] = useState([]);
    const [notFound, setNotFound] = useState(false);

    const currentLang = props.currentLang;
    const postId = props.postId;

    useEffect(() => {
        setPostData([]);
        setNotFound(false);
        const fetchData = async () => {
            const res = await contentfulClient.getEntries({
                content_type: 'works',
                locale: currentLang,
                'fields.slug': postId,
                limit: 1,
                include: 3,
            });
            setPostData([]);
            if (res.items.length === 1 && res.items[0].fields.title) {
                setPostData(res.items[0].fields);
            } else {
                setNotFound(true);
            };
        };
        fetchData();
    }, [currentLang, postId]);

    const { t } = useTranslation();

    if (postData.title) {
        return (
            <Fragment>

                <Helmet pageTitle={postData.title} lang={currentLang} pageThumb={postData.thumb ? postData.thumb.fields.file.url : null} pageDescription={postData.description}/>

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 pb_md--100 pb_sm--100  bg_image" style={{backgroundImage: `url(${postData.thumb ? postData.thumb.fields.file.url + "?fit=thumb&w=1920&h=600" : '/assets/img/post_placeholder/details/works.jpg'})`}} data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">{postData.title}</h2>
                                    <p style={{whiteSpace: 'pre-line'}}>{postData.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="row row--35 mb--50">
                                        <div className="col-lg-6">
                                            <div className="inner">
                                                <div className="section-title">
                                                    <h2 className="title">{postData.titleShort ? postData.titleShort : 'Our Product'}</h2>
                                                    <p className="description mt--30" style={{whiteSpace: 'pre-line'}}>{postData.descriptionLong}</p>
                                                </div>
                                                <div className="portfolio-view-list d-flex flex-wrap">

                                                    {postData.dateWorked ?
                                                        <div className="port-view">
                                                            <span>Project Date</span>
                                                            <h4>{moment(postData.dateWorked).format('MMMM DD, YYYY')}</h4>
                                                        </div>
                                                    : null}
                                                    {postData.tag ?
                                                        <div className="port-view">
                                                            <span>Project Area</span>
                                                            <h4>
                                                                {postData.tag.map((key , index) => (
                                                                    <Fragment key={index}>{key.fields.name}{postData.tag.length >= index+2 ? ', ' : ''}</Fragment>
                                                                ))}
                                                            </h4>
                                                        </div>
                                                    : null}

                                                </div>
                                                {postData.equipments ?
                                                    <Fragment>
                                                        {postData.equipments.length >= 5 && postData.url ?
                                                            <div className="portfolio-details-btn mt--30">
                                                                <a className="btn-default btn-border" href={postData.url} target="_blank" rel="noopener noreferrer">{t("works.details.launch-project-website")}</a>
                                                            </div>
                                                        : null}
                                                    </Fragment>
                                                : null}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="portfolio-details-accordion mt_md--40 mt_sm--40">
                                                <div className="inner">
                                                    <div className="section-title">
                                                        {postData.intention ?
                                                            <Fragment>
                                                                <h4 className="title">{t("works.details.purpose-goals-intentions")}</h4>
                                                                <p className="description mt--30" style={{whiteSpace: 'pre-line'}}>{postData.intention}</p>
                                                            </Fragment>
                                                        : null}
                                                        {postData.equipments ?
                                                            <Fragment>
                                                                <h5 className="title">{t("works.details.tools-used")}:</h5>
                                                                <ul className="list-style--1 d-flex flex-wrap">
                                                                    {postData.equipments.map((tool, index) => (
                                                                        <li key={index} style={{width: '50%'}}><FiCheck /> {tool.fields.name}
                                                                            <ul className="list-style--1" style={{ marginLeft: '2rem' }}>
                                                                                {tool.fields.tag.map((tag , index) => (
                                                                                    <li key={index} style={{fontSize: 12, marginBottom: 0}}>{tag.fields ? tag.fields.name : ''}</li>
                                                                                ))}
                                                                            </ul>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </Fragment>
                                                        : null}
                                                    </div>
                                                    {postData.equipments ?
                                                        <Fragment>
                                                            {postData.equipments.length < 4 && postData.url ?
                                                                <div className="portfolio-details-btn mt--30">
                                                                    <a className="btn-default btn-border" href={postData.url} target="_blank" rel="noopener noreferrer">{t("works.details.launch-project-website")}</a>
                                                                </div>
                                                            : null}
                                                        </Fragment>
                                                    : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="portfolio-thumb-inner">

                                        {postData.behindTheStory || postData.achievedList ?
                                            <div className="rn-columns-area info-area ptb--40">
                                                <div className="container">
                                                    <div className="row align-items-end">
                                                        <div className="col-lg-6 col-12">
                                                            <div className="section-title text-left">
                                                                <span className="subtitle">Behind the story...</span>
                                                                <h2 className="title">Project Details</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {postData.behindTheStory ?
                                                        <ReactMarkdown  linkTarget="_blank" children={postData.behindTheStory} />
                                                    : null}
                                                    {postData.achievedList ?
                                                        <div className="accordion-wrapper mt--30">
                                                            <ul className="list-style--1">
                                                                {postData.achievedList ? postData.achievedList.map((workPostAchieved , workPostAchievedIndex) => (
                                                                    <li key={workPostAchievedIndex}><FiCheck /> {workPostAchieved}</li>
                                                                )) : null}
                                                            </ul>
                                                        </div>
                                                    : null}
                                                </div>
                                            </div>
                                        : null}

                                        {postData.resourcesImg ?
                                            <Fragment>
                                                <div className="rn-columns-area info-area ptb--40">
                                                    <div className="container">
                                                        <div className="row align-items-end">
                                                            <div className="col-lg-6 col-12">
                                                                <div className="section-title text-left">
                                                                    <span className="subtitle">Screenshots, during work...</span>
                                                                    <h2 className="title">Photos</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    {postData.resourcesImg.map((img , index) => (
                                                        <div className="col-lg-4" key={index}>
                                                            {parentThis.state.gallaryIsOpen && (
                                                                <Lightbox
                                                                    mainSrc={postData.resourcesImg[parentThis.state.gallaryTab].fields.file.url}
                                                                    nextSrc={postData.resourcesImg[(parentThis.state.gallaryTab + 1) % postData.resourcesImg.length].fields.file.url}
                                                                    prevSrc={postData.resourcesImg[(parentThis.state.gallaryTab + postData.resourcesImg.length - 1) % postData.resourcesImg.length].fields.file.url}
                                                                    onCloseRequest={() => parentThis.setState({ gallaryIsOpen: false })}
                                                                    onMovePrevRequest={() =>
                                                                        parentThis.setState({
                                                                            gallaryTab: (parentThis.state.gallaryTab + postData.resourcesImg.length - 1) % postData.resourcesImg.length,
                                                                    })
                                                                    }
                                                                    onMoveNextRequest={() =>
                                                                        parentThis.setState({
                                                                            gallaryTab: (parentThis.state.gallaryTab + 1) % postData.resourcesImg.length,
                                                                        })
                                                                    }
                                                                />
                                                            )}
                                                            <div onClick={() => parentThis.setState({ gallaryIsOpen: true, gallaryTab: index })}>
                                                                <div className="portfolio-static">
                                                                    <div className="thumbnail-inner">
                                                                        <div className="thumbnail">
                                                                            <Link to="#">
                                                                                <img src={`${img.fields.file.url}?fit=thumb&w=650&h=433`} alt="Portfolio Images"/>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <div className="inner">
                                                                            <p><Link to="#">{img.fields.title}</Link></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    </div>
                                                </Fragment>
                                            : null}

                                        {postData.resourcesYt ?
                                            <Fragment>
                                                <div className="rn-columns-area info-area ptb--40">
                                                    <div className="container">
                                                        <div className="row align-items-end">
                                                            <div className="col-lg-6 col-12">
                                                                <div className="section-title text-left">
                                                                    <span className="subtitle">Video screenshots, video products...</span>
                                                                    <h2 className="title">Videos</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {postData.resourcesYt ? postData.resourcesYt.map((yt, index) => (
                                                    <div key={index} className="thumb position-relative mb--30">
                                                        <img src={"https://img.youtube.com/vi/" + yt + "/maxresdefault.jpg"} alt="Portfolio Images"/>
                                                        <button className="video-popup position-top-center" onClick={() => parentThis.setState({ ytIsOpen: true, ytOpenId: yt })}><span className="play-icon"></span></button>
                                                    </div>
                                                )) : null}
                                                <div className="thumb position-relative mb--30">
                                                    {parentThis.state.ytIsOpen ? (
                                                        <ModalVideo channel='youtube' isOpen={parentThis.state.ytOpenId} videoId={parentThis.state.ytOpenId} onClose={() => parentThis.setState({ ytIsOpen: false, ytOpenId: null })} />
                                                    ) : null}
                                                </div>
                                            </Fragment>
                                        : null}

                                        {postData.resourcesEmbed || ((postData.resourcesUrl && postData.resourcesUrlTitle)) ?
                                            <Fragment>
                                                {postData.resourcesEmbed || (postData.resourcesUrl.length === postData.resourcesUrlTitle.length) ?
                                                    <div className="rn-columns-area info-area ptb--40">
                                                        <div className="container">
                                                            <div className="row align-items-end">
                                                                <div className="col-lg-6 col-12">
                                                                    <div className="section-title text-left">
                                                                        <span className="subtitle">Documents, links, audio files...</span>
                                                                        <h2 className="title">Other Resources</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {postData.resourcesEmbed ? <div dangerouslySetInnerHTML={{__html: postData.resourcesEmbed}} /> : null}
                                                            {((postData.resourcesUrl && postData.resourcesUrlTitle)) ?
                                                                <ul className="list-style--1">
                                                                    {postData.resourcesUrl.map((res, index) => (
                                                                        <li key={index}><FiGlobe /> <a href={res} target="_blank" rel="noopener noreferrer">{postData.resourcesUrlTitle[index]}</a></li>
                                                                    ))}
                                                                </ul>
                                                            : null}
                                                        </div>
                                                    </div>
                                                : null}
                                            </Fragment>
                                        : null}

                                        {postData.credit && postData.creditTitle && postData.copyright ?
                                            <div className="rn-columns-area info-area ptb--40">
                                                <div className="container">
                                                    <div className="row align-items-end">
                                                        <div className="col-lg-6 col-12">
                                                            <div className="section-title text-left">
                                                                <span className="subtitle">Copyrights, people involved in...</span>
                                                                <h2 className="title">Project Overview</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {postData.credit && postData.creditTitle ?
                                                        <Fragment>
                                                            <h4>Credits</h4>
                                                            {(postData.credit.length === postData.creditTitle.length) ?
                                                                <ul className="list-style--1">
                                                                    {postData.credit.map((credit, index) => (
                                                                        <li key={index}>{credit.sys.contentType.sys.id === 'worksOrganization' ? <FiUsers /> : <FiUser />} {postData.creditTitle[index]} - {credit.fields.name}{credit.fields.organization ? ' (' + credit.fields.organization.fields.name + ') ' : ' '}
                                                                        {credit.fields.websiteUrl ? <a href={credit.fields.websiteUrl} target="_blank" rel="noopener noreferrer"><FaGlobeAmericas /></a> : null}
                                                                        {credit.fields.twitterId ? <a href={`https://twitter.com/${credit.fields.twitterId}`} target="_blank" rel="noopener noreferrer"><FaTwitter /></a> : null}
                                                                        {credit.fields.instagramId ? <a href={`https://www.instagram.com/${credit.fields.instagramId}`} target="_blank" rel="noopener noreferrer"><FaInstagram /></a> : null}
                                                                        {credit.fields.githubId ? <a href={`https://github.com/${credit.fields.githubId}`} target="_blank" rel="noopener noreferrer"><FaGithub /></a> : null}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            : null}
                                                        </Fragment>
                                                    : null}
                                                    {postData.copyright ?
                                                        <Fragment>
                                                            <h4>Copyright</h4>
                                                            <p>{postData.copyright}</p>
                                                        </Fragment>
                                                    : null}
                                                </div>
                                            </div>
                                        : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                {/* <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">Related Work</span>
                                    <h2 className="title">Our More Projects</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--10"> */}
                            {/* Start Single Portfolio */}
                            {/* <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/portfolio-details">
                                            <img src="/assets/images/portfolio/related-image-01.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/portfolio-details">Digital Analysis</a></h4>
                                        <span className="category">Technique</span>
                                    </div>
                                </div>
                            </div> */}
                                {/* End Single Portfolio */}
                            {/* Start Single Portfolio */}
                            {/* <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/portfolio-details">
                                            <img src="/assets/images/portfolio/related-image-02.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/portfolio-details">Plan Management</a></h4>
                                        <span className="category">PLANNING</span>
                                    </div>
                                </div>
                            </div> */}
                                {/* End Single Portfolio */}
                        {/* </div>
                    </div>
                </div> */}
                {/* End Related Work */}

            </Fragment>

        )
    } else {
        if (notFound) {
            return <Error404Content />
        } else {
            return(
                <Fragment>

                    <Helmet pageTitle="Loading..." lang={currentLang} />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 pb_md--100 pb_sm--100  bg_image" style={{backgroundImage: 'url(/assets/img/post_placeholder/details/works.jpg)'}} data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Loading...</h2>
                                    <p>Now loading my previous work...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="row row--35 mb--50">
                                        <div className="col-lg-6">
                                            <div className="inner">
                                                <div className="section-title">
                                                    <h2 className="title">Lorem Ipsum</h2>
                                                    <p className="description mt--30">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vitae aliquet risus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nulla fringilla et urna eu gravida. Donec eu faucibus est, eu tincidunt ligula. Vestibulum ullamcorper felis ligula, finibus dignissim purus congue eu. Integer ut iaculis libero. Mauris at ante ut dolor egestas feugiat in sed nisl. Maecenas mollis pulvinar diam, elementum vulputate turpis hendrerit a. Ut mauris turpis, rhoncus sodales odio a, mollis porttitor lectus. Ut nisl tellus, pellentesque et aliquet at, commodo in erat. Vivamus laoreet ultrices sagittis. Donec tortor risus, lobortis vel libero quis, euismod posuere nunc. Maecenas enim tellus, fringilla quis elementum non, mattis ac massa. Praesent quis porta justo. Suspendisse lacinia nec neque vitae varius. Sed ac dictum odio, id cursus felis.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="portfolio-details-accordion mt_md--40 mt_sm--40">
                                                <div className="inner">
                                                    <div className="section-title">
                                                        <h4 className="title">Lipsum</h4>
                                                        <p className="description mt--30">Etiam rhoncus quis purus quis auctor. Vivamus nec imperdiet neque, vel semper arcu. Vestibulum vitae diam ut nibh consectetur tincidunt. Praesent quis augue cursus, cursus lectus ac, pretium lectus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget justo id sapien pellentesque iaculis ut non magna. Nam dictum nec eros non porta. Aliquam id ullamcorper nisi, non dapibus dolor. Praesent malesuada, odio vel fringilla vehicula, sapien nunc iaculis turpis, vitae porttitor risus augue ac quam. Nam blandit, mauris a efficitur dapibus, elit ipsum consectetur libero, ut porta purus mi nec sapien. Integer fermentum fermentum lectus, vel egestas justo congue quis. Vivamus viverra, nisl in feugiat ultricies, lorem metus venenatis lectus, et imperdiet est nisi a mauris. Sed feugiat mattis quam non egestas. Vivamus quis pulvinar felis. Integer pretium interdum ante non tincidunt.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                </Fragment>
            )
        }
    }
}
export default WorksContent;