import React, { Fragment, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { contentfulClient } from '../../../contentfulClient.js'
import { useTranslation } from 'react-i18next';

import moment from 'moment'

function PublicationsList(props) {
    const [postData, setPostData] = useState([]);

    const currentLang = props.currentLang;

    useEffect(() => {
        const fetchData = async () => {
            const res = await contentfulClient.getEntries({
                content_type: 'works',
                locale: currentLang,
                "fields.isPublication": true,
                order: '-fields.dateWorked',
                limit: 4,
            });
            setPostData(res.items);
        };
        fetchData();
    }, [currentLang]);

    const { t } = useTranslation();

    if (postData.length > 0) {
        return(
            <Fragment>

                <div className="rn-columns-area info-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-6 col-12">
                                <div className="section-title text-left">
                                    <span className="subtitle">What's new</span>
                                    <h2 className="title">News</h2>
                                    <p className="description">{t('home.news.description')}</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="info-btn text-left text-lg-right mt_sm--10 mt_md--10">
                                    <Link className="btn-transparent rn-btn-dark" to="/news"><span className="text">View All News</span></Link>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--30">
                            <div className="col-lg-12">
                                <div className="single-column info">
                                {postData.map((post, index) => {
                                    if (post.fields.title && post.fields.slug && post.fields.datePosted) {
                                        return(
                                            <div key={index}>
                                                <p className="date">{moment(post.fields.datePosted).format('MMMM DD, YYYY')}</p>
                                                <p className="title"><Link to={'/news/' + post.fields.slug}>{post.fields.title}</Link></p>
                                            </div>
                                            )
                                        } else {
                                            return null;
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    } else {
        return(
            <Fragment>

                <div className="rn-columns-area info-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-6 col-12">
                                <div className="section-title text-left">
                                    <span className="subtitle">What's new</span>
                                    <h2 className="title">News</h2>
                                    <p className="description">{t('home.news.description')}</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="info-btn text-left text-lg-right mt_sm--10 mt_md--10">
                                    <Link className="btn-transparent rn-btn-dark" to="/news"><span className="text">View All News</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}
export default PublicationsList;