import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";
import { withTranslation } from 'react-i18next';

class Header extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            // console.log('All assets are loaded')
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    render(){
        const languageMap = {
            "ja-JP": { label: "日本語"},
            "en-US": { label: "English"},
        };

        const { i18n } = this.props;

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        const { logo, color='default-color' } = this.props;
        let logoUrl;
        if(logo === 'light'){
            logoUrl = <img src="//res.386.jp/logo/full-light.svg" alt="386.JP" />;
        }else if(logo === 'dark'){
            logoUrl = <img src="//res.386.jp/logo/full.svg" alt="386.JP" />;
        }else if(logo === 'symbol-dark'){
            logoUrl = <img src="//res.386.jp/logo/1x1.svg" alt="386.JP" />;
        }else if(logo === 'symbol-light'){
            logoUrl = <img src="//res.386.jp/logo/1x1-light.svg" alt="386.JP" />;
        }else{
            logoUrl = <img src="//res.386.jp/logo/full.svg" alt="386.JP" />;
        }

        return(
            <header className={`header-area header--fixed formobile-menu header--transparent ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <Link to="/">
                                {logoUrl}
                            </Link>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">


                            <li><Link to="/news">News</Link></li>
                            <li><Link to="/works">Works</Link></li>
                            {/* <li><Link to="/media">Media</Link></li> */}
                            <li><Link to="/about">Biography</Link></li>
                            <li><Link to="/blogs">Blogs</Link></li>
                            <li><Link to="/contact">Contact</Link></li>

                                {/* <li className="has-droupdown"><a href="javascript:void(0)">Pages</a>
                                    <ul className="submenu">
                                        <li><Link to="/blog">Blog List</Link></li>
                                    </ul>
                                </li> */}

                            </ul>
                        </nav>

                        {/* Select Language */}
                        {Object.keys(languageMap)?.map((lang, index) => {
                            if (lang !== i18n.language) {
                                return (
                                    <div className="header-btn" key={index}>
                                        <button className="btn-default" onClick={() => i18n.changeLanguage(lang)}>
                                            <span>{languageMap[lang].label}</span>
                                        </button>
                                    </div>
                                )
                            } else {
                                return null;
                            }
                        })}

                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>

            </header>
        )
    }
}
export default withTranslation('translation')(Header);