import React, { Fragment, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Helmet from "../../../component/common/Helmet";
import { contentfulClient } from '../../../contentfulClient.js'
import { useTranslation } from 'react-i18next';

import Error404Content from '../../../component/body/error/404';


import Slider from "react-slick";

import moment from 'moment'
import ModalVideo from 'react-modal-video';
import { FiCheck, FiGlobe, FiUser, FiUsers } from "react-icons/fi";
import { FaGlobeAmericas, FaTwitter, FaInstagram, FaGithub } from "react-icons/fa";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import ReactMarkdown from 'react-markdown';

import BlogList from '../../../component/body/portfolio/blogList';
import WorkList from '../../../component/body/portfolio/workList';
import ExperiencesList from '../../../component/body/portfolio/experiencesList';
import QualificationsList from '../../../component/body/portfolio/qualificationsList';
import AwardsList from '../../../component/body/portfolio/awardsList';
import MediaList from '../../../component/body/portfolio/mediaList';
import PublicationsList from '../../../component/body/portfolio/publicationsList';


export const slickConfig = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    adaptiveHeight: true
};



function PortfolioContent(props) {
    const parentThis = props.this;

    const [postData, setPostData] = useState([]);
    const [experienceData, setExperienceData] = useState([]);
    const [qualificationsData, setQualificationsData] = useState([]);
    const [awardsData, setAwardsData] = useState([]);
    const [mediaData, setMediaData] = useState([]);
    const [publicationData, setPublicationData] = useState([]);

    const currentLang = props.currentLang;
    const postId = props.postId;

    useEffect(() => {
    const fetchData = async () => {
        const res = await contentfulClient.getEntries({
            content_type: 'portfolioPage',
            locale: currentLang,
            'fields.slug': postId,
            limit: 1,
            include: 3,
        });
        if (res.items.length === 1) {
            setPostData(res.items[0].fields);
        };
    };
    fetchData();

    }, [currentLang, postId]);

    const { t } = useTranslation();

    return (
        <Fragment>

            <Helmet pageTitle={t("about.name")} lang={currentLang} pageThumb={postData.headerImage ? postData.headerImage.fields.file.url : "https://www.386.jp/assets/img/portfolio/header.jpg"} />

            {/* Start Slider Area   */}
            <div id="home" className="fix">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    <div className="slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_overlay bg_image" style={{backgroundImage: `url(${postData.headerImage ? postData.headerImage.fields.file.url + "?fit=thumb&w=1920&h=950" : '/assets/img/portfolio/header.jpg'})`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className='inner text-left'>
                                        {postData.name ?
                                            <span className="theme-color font-700">{postData.name}</span>
                                        : ""}
                                        <h1 className="title theme-gradient">{postData.bigGoal ? postData.bigGoal : "Hello"}</h1>
                                        <p className="description">{postData.myTitle ? postData.myTitle + "   -   " : ""}{t("about.name")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */}

            {/* Start About Area   */}
            <div id="about" className="fix">
                <div className="about-area ptb--120 bg_color--5">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <span className="subtitle">About me, to the future...</span>
                                            <h2 className="title">{t("about.name")}</h2>
                                            <p className="description mt_dec--20" style={{whiteSpace: 'pre-line'}}>{postData.description ? postData.description : t("about.bio")}</p>
                                            {postData.goals ?
                                                <p className="description mt_dec--20" style={{whiteSpace: 'pre-line'}}>{postData.goals}</p>
                                            : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/img/portfolio/profile.jpg" alt="About Images"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End About Area   */}

            {/* Start Experiences Area */}
            {/* <ExperiencesList currentLang={currentLang} /> */}
            {/* End Experiences Area */}

            {/* Start About Area  */}
            {postData.featuredWorks ? postData.featuredWorks.map((workPost , workIndex) => (
                <div className={`about-area ${(postData.featuredWorks.length - 1) == workIndex ? "ptb--120" : "pt--120"}  bg_color--7`} key={workIndex}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-6">
                                    <Slider className="thumbnail" {...slickConfig}>
                                        {workPost.fields.thumb ?
                                        <img className="w-100" src={workPost.fields.thumb.fields.file.url + "?fit=thumb&w=600&h=650"} alt="About Images"/>
                                        : null}
                                        {workPost.fields.resourcesImg ? workPost.fields.resourcesImg.slice(0, 9).map((workPostResImg , workPostResImgIndex) => (
                                            <img className="w-100" src={workPostResImg.fields.file.url + "?fit=thumb&w=600&h=650"} alt="About Images" key={workPostResImgIndex}/>
                                        )) : null}
                                    </Slider>
                                </div>
                                <div className="col-lg-6">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <span className="subtitle">Featured Work: {workPost.fields.tag ? workPost.fields.tag[0].fields.name : "My Best Work"}</span>
                                            <h2 className="title">{workPost.fields.title ? (workPost.fields.titleShort ? workPost.fields.titleShort : workPost.fields.title) : ""}</h2>
                                            <p style={{whiteSpace: 'pre-line'}}>{workPost.fields.descriptionLong ? workPost.fields.descriptionLong : ""}</p>
                                            {/* <p style={{whiteSpace: 'pre-line'}}>{workPost.fields.intention ? workPost.fields.intention : ""}</p> */}
                                        </div>
                                        <div className="accordion-wrapper mt--30">
                                            <ul className="list-style--1">
                                                {workPost.fields.achievedList ? workPost.fields.achievedList.map((workPostAchieved , workPostAchievedIndex) => (
                                                    <li key={workPostAchievedIndex}><FiCheck /> {workPostAchieved}</li>
                                                )) : null}
                                            </ul>
                                        </div>
                                        <div className="about-button mt--30">
                                            <Link className="btn-default" to={`/works/${workPost.fields.slug ? workPost.fields.slug : ""}`}>Learn More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )) : null}
            {/* End About Area  */}

            {/* Start Portfolio Area */}
            {/* <div className="portfolio-area ptb--120 bg_color--1">
                <div className="portfolio-sacousel-inner mb--55 mb_sm--0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30 mb_sm--0">
                                    <span className="subtitle">Our project</span>
                                    <h2 className="title">Some of My Recent Works</h2>
                                    <p className="description">There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                    <a className="btn-default" href="/blog"><span>View Projects</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* End Portfolio Area */}

            {/* Start Portfolio Area */}
            <WorkList currentLang={currentLang} />
            {/* End Portfolio Area */}

            {/* Start Blog Area */}
            <BlogList currentLang={currentLang} />
            {/* End Blog Area */}


        </Fragment>

    )
}
export default PortfolioContent;