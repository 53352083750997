import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

import { withTranslation } from 'react-i18next';

import WorksContent from "../../component/body/details/worksContent";

class WorksDetails extends Component{
    constructor () {
        super()
        this.state = {
            ytIsOpen: false,
            ytOpenId: null,
            gallaryTab: 0,
            gallaryIsOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal (ytId) {
        this.setState({ytOpenId: ytId})
    }
    render(){
        const { i18n } = this.props;

        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 50) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        return(
            <React.Fragment>

                <Header />

                {/* Start Blog Details */}
                <WorksContent currentLang={i18n.language} postId={this.props.match.params.slug} this={this} />
                {/* End Blog Details */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default withTranslation('translation')(WorksDetails);