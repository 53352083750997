import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../../component/common/Helmet";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import { withTranslation } from 'react-i18next';

import PortfolioContent from "../../component/body/portfolio/portfolioContent.jsx";




class PortfolioDetails extends Component{
    render(){
        const { t, i18n } = this.props;

        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 50) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });




        return(
            <Fragment>
                {/* <Helmet pageTitle={t("about.name")} lang={i18n.language} pageThumb="https://www.386.jp/assets/img/portfolio/header.jpg" /> */}

                {/* Start Header Area  */}
                <Header/>
                {/* End Header Area  */}

                <PortfolioContent currentLang={i18n.language} postId={this.props.match.params.slug ? this.props.match.params.slug : "default"} postUrl={window.location.origin + this.props.location.pathname} />


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </Fragment>
        )
    }
}
export default withTranslation('translation')(PortfolioDetails);