import React, { Component } from 'react';
import Header from "../component/header/Header";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/Footer";
import Helmet from "../component/common/Helmet";

import Error404Content from '../component/body/error/404';

 class error404 extends Component {

    render() {

        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 50) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        return (
            <>
                <Helmet pageTitle="Page Not Found" />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" headertransparent="header--transparent" colorblack="color--black" />
                {/* End Header Area  */}

                {/* Start Page Error  */}
                <Error404Content />
                {/* End Page Error  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />
            </>
        )
    }
}
export default error404;
