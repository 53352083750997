import React, { Fragment, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { contentfulClient } from '../../../contentfulClient.js'
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";

export const slickConfig = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    responsive: [
        {
            breakpoint: 993,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 580,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 481,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
};

function BlogList(props) {
    const [postData, setPostData] = useState([]);


    const currentLang = props.currentLang;

    useEffect(() => {
        const fetchData = async () => {
            const res = await contentfulClient.getEntries({
                content_type: 'blogs',
                locale: currentLang,
                order: '-fields.datePosted',
                limit: 10,
            });
            setPostData(res.items);
        };
        fetchData();
    }, [currentLang]);

    const { t } = useTranslation();

    if (postData.length > 0) {
        return(
            <Fragment>

                <div className="rn-blog-area pt--120 pb--140 bg_color--1" id="blog">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12">
                                <div className="section-title text-left">
                                    <span className="subtitle">What's stories on</span>
                                    <h2 className="title">Blogs</h2>
                                    <p className="description">{t('home.blogs.description')}</p>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                                    <Link className="btn-transparent rn-btn-dark" to="/blogs"><span className="text">View All Blogs</span></Link>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--10 rn-slick-dot slick-space-gutter--15 slickdot--20 row--0">
                            <div className="col-lg-12">
                                <Slider {...slickConfig}>
                                    {postData.map((post , index) => {
                                        if (post.fields.title && post.fields.slug) {
                                            if (post.fields.externalUrl) {
                                                return (
                                                    <div className="im_box" key={index}>
                                                        <div className="thumbnail">
                                                            <a href={post.fields.externalUrl} target="_blank" rel="noopener noreferrer">
                                                                <img className="w-100" src={post.fields.thumb && post.fields.thumb.fields.file ? post.fields.thumb.fields.file.url + "?fit=thumb&w=390&h=532" : '/assets/img/post_placeholder/landing/blogs.jpg'} alt="Blog Thumbnail"/>
                                                            </a>
                                                        </div>
                                                        <div className="content">
                                                            <div className="inner">
                                                                <div className="content_heading">
                                                                    <div className="category_list">
                                                                        <a href={post.fields.externalUrl} target="_blank" rel="noopener noreferrer">{post.fields.externalName ? "#" + post.fields.externalName : t('blogs.tags.external')}</a>
                                                                    </div>
                                                                    <h4 className="title">
                                                                        <a href={post.fields.externalUrl} target="_blank" rel="noopener noreferrer">{post.fields.title}</a>
                                                                    </h4>
                                                                </div>
                                                                <div className="content_footer">
                                                                    <a href={post.fields.externalUrl} target="_blank" rel="noopener noreferrer" className="rn-btn btn-opacity">Read More</a>
                                                                </div>
                                                            </div>
                                                            <a className="transparent_link" href={post.fields.externalUrl} target="_blank" rel="noopener noreferrer"> </a>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div className="im_box" key={index}>
                                                        <div className="thumbnail">
                                                            <Link to={'/blogs/' + post.fields.slug}>
                                                                <img className="w-100" src={post.fields.thumb && post.fields.thumb.fields.file ? post.fields.thumb.fields.file.url + "?fit=thumb&w=390&h=532" : '/assets/img/post_placeholder/landing/blogs.jpg'} alt="Blog Thumbnail"/>
                                                            </Link>
                                                        </div>
                                                        <div className="content">
                                                            <div className="inner">
                                                                <div className="content_heading">
                                                                    <div className="category_list">
                                                                        <Link to={'/blogs/' + post.fields.slug}>{post.fields.tag && post.fields.tag.length > 0 ? t('blogs.tags.' + post.fields.tag[0]) : t('blogs.tags.fallback')}</Link>
                                                                    </div>
                                                                    <h4 className="title">
                                                                        <Link to={'/blogs/' + post.fields.slug}>{post.fields.title}</Link>
                                                                    </h4>
                                                                </div>
                                                                <div className="content_footer">
                                                                    <Link to={'/blogs/' + post.fields.slug} className="rn-btn btn-opacity">Read More</Link>
                                                                </div>
                                                            </div>
                                                            <Link className="transparent_link" to={'/blogs/' + post.fields.slug}></Link>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        } else {
                                            return null
                                        }
                                    })}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    } else {
        return(
            <Fragment>

                <div className="rn-blog-area pt--120 pb--140 bg_color--1" id="blog">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12">
                                <div className="section-title text-left">
                                    <span className="subtitle">What's stories on</span>
                                    <h2 className="title">Blogs</h2>
                                    <p className="description">{t('home.blogs.description')}</p>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                                    <Link className="btn-transparent rn-btn-dark" to="/blogs"><span className="text">View All Blogs</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}
export default BlogList;