import PropTypes from "prop-types";
import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <html lang={this.props.lang}></html>
                    <meta property="og:locale" content={this.props.lang} />
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                    <meta property="og:url" content={window.location.href} />
                    <title>{this.props.pageTitle} | 386.JP</title>
                    <meta property="og:title" content={this.props.pageTitle} />
                    <meta name="description" content={this.props.pageDescription ? this.props.pageDescription : "宮村 京佑のポートフォリオサイトです。"} />
                    <meta property="og:description" content={this.props.pageDescription ? this.props.pageDescription : "宮村 京佑のポートフォリオサイトです。"} />
                    <meta property="og:image" content={this.props.pageThumb ? this.props.pageThumb : "https://www.386.jp/assets/img/ogp/thumb.png"} />
                </Helmet>
            </React.Fragment>
        )
    }
}
PageHelmet.propTypes = {
    title: PropTypes.string
};
export default PageHelmet;
