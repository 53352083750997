import React, { Fragment, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { contentfulClient } from '../../../contentfulClient.js'

function BlogsListContent(props) {
    const [postData, setPostData] = useState([]);
    const [totalPosts, setTotalPosts] = useState(0);
    const [notFound, setNotFound] = useState(false);


    const currentLang = props.currentLang;
    const currentPage = props.currentPage;

    useEffect(() => {
        setPostData([]);
        setNotFound(false);
        const fetchData = async () => {
            const res = await contentfulClient.getEntries({
                content_type: 'blogs',
                locale: currentLang,
                order: '-fields.datePosted',
            });
            setPostData([]);
            if (res.items.length > 0 && res.items[0].fields.title) {
                setPostData(res.items);
                // console.log(res);
                setTotalPosts(res.total);
            } else {
                setNotFound(true);
            };
        };
        fetchData();
    }, [currentLang, currentPage]);

    if (postData.length > 0) {
        return(
            <Fragment>

                {/* Start Contents Area */}
                <div className="row mt_dec--30 ptb--50">
                    {postData.map((post , i ) => (
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30" key={i}>
                            <div className="im_box">
                                <div className="thumbnail">
                                    <Link to={'/blogs/' + post.fields.slug}>
                                        <img className="w-100" src={post.fields.thumb && post.fields.thumb.fields.file ? post.fields.thumb.fields.file.url + "?fit=thumb&w=390&h=532" : '/assets/img/post_placeholder/list/blogs.jpg'} alt="Blogs Thumbnail"/>
                                    </Link>
                                </div>
                                <div className="content">
                                    <div className="inner">
                                        <div className="content_heading">
                                            <div className="category_list">
                                                <Link to={'/blogs/' + post.fields.slug}>{post.fields.tag && post.fields.tag.length > 0 ? post.fields.tag[0] : '今日のひとこと'}</Link>
                                            </div>
                                            <h4 className="title">
                                                <Link to={'/blogs/' + post.fields.slug}>{post.fields.title}</Link>
                                            </h4>
                                        </div>
                                        <div className="content_footer">
                                            <Link to={'/blogs/' + post.fields.slug} className="rn-btn btn-opacity">Read More</Link>
                                        </div>
                                    </div>
                                    <Link className="transparent_link" to={'/blogs/' + post.fields.slug}></Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* End Contents Area */}

            </Fragment>
        )
    } else {
        if (notFound) {
            return(
                <Fragment>

                {/* Start Contents Area */}
                <div className="row mt_dec--30 ptb--50 mt--50 mb--150">

                    <h2>Blogs Not Found.</h2>

                </div>
                {/* End Contents Area */}

                </Fragment>
            )
        } else {
            return(
                <Fragment>

                {/* Start Contents Area */}
                <div className="row mt_dec--30 ptb--50">

                    <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30">
                        <div className="im_box">
                            <div className="thumbnail">
                                <Link to="#">
                                    <img className="w-100" src="/assets/img/post_placeholder/list/blogs.jpg" alt="Blog Thumbnail"/>
                                </Link>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="content_heading">
                                        <div className="category_list">
                                            <Link to="#">今日のひとこと</Link>
                                        </div>
                                        <h4 className="title">
                                            <Link to="#">Loading...</Link>
                                        </h4>
                                    </div>
                                    <div className="content_footer">
                                        <Link to="#" className="rn-btn btn-opacity">Read More</Link>
                                    </div>
                                </div>
                                <Link className="transparent_link" to="#"></Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30">
                        <div className="im_box">
                            <div className="thumbnail">
                                <Link to="#">
                                    <img className="w-100" src="/assets/img/post_placeholder/list/blogs.jpg" alt="Blog Thumbnail"/>
                                </Link>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="content_heading">
                                        <div className="category_list">
                                            <Link to="#">今日のひとこと</Link>
                                        </div>
                                        <h4 className="title">
                                            <Link to="#">Loading...</Link>
                                        </h4>
                                    </div>
                                    <div className="content_footer">
                                        <Link to="#" className="rn-btn btn-opacity">Read More</Link>
                                    </div>
                                </div>
                                <Link className="transparent_link" to="#"></Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30">
                        <div className="im_box">
                            <div className="thumbnail">
                                <Link to="#">
                                    <img className="w-100" src="/assets/img/post_placeholder/list/blogs.jpg" alt="Blog Thumbnail"/>
                                </Link>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="content_heading">
                                        <div className="category_list">
                                            <Link to="#">今日のひとこと</Link>
                                        </div>
                                        <h4 className="title">
                                            <Link to="#">Loading...</Link>
                                        </h4>
                                    </div>
                                    <div className="content_footer">
                                        <Link to="#" className="rn-btn btn-opacity">Read More</Link>
                                    </div>
                                </div>
                                <Link className="transparent_link" to="#"></Link>
                            </div>
                        </div>
                    </div>

                </div>
                {/* End Contents Area */}

                </Fragment>
            )
        }
    }
}
export default BlogsListContent;