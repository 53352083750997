import React, { Fragment, useState, useEffect } from "react";
// import { Link } from 'react-router-dom';
import Helmet from "../../../component/common/Helmet";
import { contentfulClient } from '../../../contentfulClient.js'
import { useTranslation } from 'react-i18next';

import Error404Content from '../../../component/body/error/404';

import ReactMarkdown from 'react-markdown';

import moment from 'moment'

// import ModalVideo from 'react-modal-video';
import { FiClock, FiMessageCircle, FiTag } from "react-icons/fi";


function BlogContent(props) {
    const [postData, setPostData] = useState([]);
    const [notFound, setNotFound] = useState(false);

    const currentLang = props.currentLang;
    const postId = props.postId;
    const postUrl = props.postUrl;

    useEffect(() => {
        setPostData([]);
        setNotFound(false);
        const fetchData = async () => {
            const res = await contentfulClient.getEntries({
                content_type: 'blogs',
                locale: currentLang,
                'fields.slug': postId,
                limit: 1,
                include: 3,
            });
            setPostData([]);
            if (res.items.length === 1 && res.items[0].fields.title) {
                setPostData(res.items[0].fields);
            } else {
                setNotFound(true);
            };
        };
        fetchData();
    }, [currentLang, postId]);

    const { t } = useTranslation();

    if (postData.title) {
        return(
            <Fragment>

                <Helmet pageTitle={postData.title} lang={currentLang} pageThumb={postData.thumb ? postData.thumb.fields.file.url : null} pageDescription={postData.description}/>

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image" style={{backgroundImage: `url(${postData.thumb ? postData.thumb.fields.file.url + "?fit=thumb&w=1920&h=950" : '/assets/img/post_placeholder/details/blogs.jpg'})`}} data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">{postData.title}</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />{moment(postData.datePosted).format('MMMM DD, YYYY')}</li>
                                        {postData.tag ?
                                            <li><FiTag />
                                                {postData.tag.map((tag , index) => (
                                                    <span key={index}>{t('blogs.tags.' + tag)}{postData.tag.length >= index+2 ? ', ' : ''}</span>
                                                ))}
                                            </li>
                                        : null}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                    {/* <ReactMarkdown  linkTarget="_blank" remarkPlugins={[gfm]} children={postData.content} /> */}
                                    <ReactMarkdown  linkTarget="_blank" children={postData.content} />

                                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut pellentesque ex nec hendrerit commodo. Ut vitae venenatis diam. Proin condimentum dolor ut erat condimentum, quis ornare ante hendrerit. Suspendisse volutpat consectetur volutpat. Pellentesque nec arcu sit amet ante gravida feugiat et mattis elit. Nulla faucibus sit amet nunc sit amet tincidunt. Mauris in feugiat felis, semper eleifend enim. Ut volutpat congue blandit. Suspendisse potenti.</p>

                                        <div className="thumbnail">
                                            <img src="/assets/images/blog/bl-big-01.jpg" alt="Blog Images"/>
                                        </div>
                                        <p className="mt--40">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend toitrrepeat predefined chunks. </p>
                                        <p>Necessary, making this the first true generator on the Internet. It re are many variations of passages of Lo
                                        rem Ipsum available, but the majority have suffered alteration in some form, by injectedeed eedhumour, or randomised words which don't look even slightly believable.</p>
                                        <blockquote className="rn-blog-quote">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes.</blockquote>
                                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet. It re are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injectedeed eedhumour, or randomised words which don't look even slightly believable.</p>
                                        <div className="blog-single-list-wrapper d-flex flex-wrap">
                                            <div className="thumbnail">
                                                <img className="w-100" src="/assets/images/blog/blog-single-01.jpg" alt="BLog Images"/>
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</span>
                                            </div>
                                            <div className="content">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo .</p>
                                                <h4 className="title">Ordered & Unordered Lists.</h4>
                                                <ul className="list-style">
                                                    <li>Yet this above sewed flirted opened ouch</li>
                                                    <li>Goldfinch realistic sporadic ingenuous</li>
                                                    <li>Abominable this abidin far successfully then like piquan</li>
                                                    <li>Risus commodo viverra</li>
                                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing</li>
                                                </ul>
                                                <h4 className="title">Ordered & Unordered Lists.</h4>
                                                <ul className="list-style">
                                                    <li>Yet this above sewed flirted opened ouch</li>
                                                    <li>Goldfinch realistic sporadic ingenuous</li>
                                                    <li>Abominable this abidin far successfully then like piquan</li>
                                                    <li>Risus commodo viverra</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <p className="mt--25 mt_sm--5">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet. It re are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injectedeed eedhumour, or randomised words which don't look even slightly believable.</p>
                                        <div className="video-wrapper position-relative mb--40">
                                            <div className="thumbnail">
                                                <img src="/assets/images/blog/bl-big-01.jpg" alt="Blog Images"/>
                                            </div> */}
                                            {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} /> */}
                                            {/* <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button> */}
                                        {/* </div>
                                        <p className="mb--0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet. It re are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injectedeed eedhumour, or randomised words which don't look even slightly believable.</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

            </Fragment>
        )
    } else {
        if (notFound) {
            return <Error404Content />
        } else {
            return(
                <Fragment>

                    <Helmet pageTitle="Loading..." lang={currentLang} />

                    {/* Start Breadcrump Area */}
                    <div className="rn-page-title-area pt--120 pb--190 bg_image" style={{backgroundImage: 'url(/assets/img/post_placeholder/details/blogs.jpg'}} data-black-overlay="7">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="blog-single-page-title text-center pt--100">
                                        <h2 className="title theme-gradient">Loading...</h2>
                                        <ul className="blog-meta d-flex justify-content-center align-items-center">
                                            <li><FiClock />Lipsum</li>
                                            <li><FiMessageCircle />Loading...</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Breadcrump Area */}

                    {/* Start Blog Details */}
                    <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="inner-wrapper">
                                        <div className="inner">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut pellentesque ex nec hendrerit commodo. Ut vitae venenatis diam. Proin condimentum dolor ut erat condimentum, quis ornare ante hendrerit. Suspendisse volutpat consectetur volutpat. Pellentesque nec arcu sit amet ante gravida feugiat et mattis elit. Nulla faucibus sit amet nunc sit amet tincidunt. Mauris in feugiat felis, semper eleifend enim. Ut volutpat congue blandit. Suspendisse potenti.</p>
                                            <p>Suspendisse potenti. Donec sed posuere ipsum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus in felis luctus metus rutrum vulputate. Sed dictum nisi vel blandit pulvinar. Nam non finibus lacus, a consectetur leo. Pellentesque id sapien mauris. Mauris sollicitudin pellentesque mollis. Duis convallis elit ut pellentesque sodales.</p>
                                            <p>Phasellus ornare enim quis dui tempus, vel varius elit interdum. Ut faucibus malesuada venenatis. Fusce dignissim sed ante id condimentum. Fusce tempor ullamcorper nisl sit amet tincidunt. Sed gravida imperdiet nisi eget varius. Vivamus ut neque id purus semper bibendum et nec ante. Fusce dapibus elit tellus, interdum ultrices leo molestie vel. Aliquam auctor purus ac condimentum fringilla. Suspendisse sollicitudin dapibus enim in fringilla. Integer malesuada, tortor vel mollis commodo, felis libero iaculis ex, in vestibulum elit libero sed nisl. Suspendisse posuere a dolor nec luctus. Nunc vel mattis nisi.</p>
                                            <p>Sed ultricies ex in nunc posuere, ut tincidunt felis consectetur. Cras blandit lobortis enim, eget dignissim purus posuere sed. Vivamus malesuada porttitor ullamcorper. Cras consequat nec urna sit amet commodo. Aenean ut mi at urna lobortis auctor nec sed mauris. Nulla facilisi. Pellentesque mi mauris, tempus sit amet velit ac, condimentum ultricies nisi. Vestibulum molestie sit amet velit sit amet luctus. Duis efficitur nisi metus, eget rutrum lorem tristique ac. Curabitur elementum velit ipsum. Aliquam dignissim pretium commodo. Nam vitae consectetur augue. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Duis eu tincidunt augue. Pellentesque consectetur nibh nunc, in dignissim nisl gravida nec.</p>
                                            <p>Quisque non condimentum mi. Fusce pellentesque pellentesque dictum. Curabitur commodo tellus in arcu volutpat dictum. Ut tempus sem quam. Curabitur varius dui ultrices, porttitor elit eget, tristique urna. Praesent sit amet ipsum tortor. Nunc accumsan massa a vestibulum vulputate. Ut a scelerisque neque, non efficitur nisi. Nullam id diam hendrerit, iaculis leo id, accumsan turpis. Nullam convallis dictum arcu. Mauris vitae sem a ligula malesuada finibus. Ut nec facilisis erat, vitae venenatis libero. In porttitor blandit justo sit amet mollis.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Blog Details */}

                </Fragment>
            )
        }
    }
}
export default BlogContent;
