import React, { Component } from "react";
// import Helmet from "../../component/common/Helmet";

// import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

import { withTranslation } from 'react-i18next';


import BlogContent from "../../component/body/details/blogContent";

class BlogDetails extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        const { i18n } = this.props;

        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 50) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        return(
            <React.Fragment>

                {/* <PageHelmet pageTitle='Blog Details' /> */}
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Blog Details */}
                <BlogContent currentLang={i18n.language} postId={this.props.match.params.slug} postUrl={window.location.origin + this.props.location.pathname} />
                {/* End Blog Details */}

                {/* Start BLog Comment Form  */}

                {/* End BLog Comment Form  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default withTranslation('translation')(BlogDetails);