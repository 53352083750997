import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';


import translationJA from '../../public/translations/ja-JP/translation.json';
import translationEN from '../../public/translations/en-US/translation.json';

// the translations
const resources = {
  "ja-JP": {
    translation: translationJA
  },
  "en-US": {
    translation: translationEN
  }
};

const availableLanguages = ['ja-JP', 'en-US'];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "ja-JP", // fallback language is Japanese.

    detection: {
      checkWhitelist: true, // options for language detection
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  });

export default i18n;