import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Error404Content(props) {
    const { t } = useTranslation();
    return (
        <Fragment>
            <div className="error-page-inner bg_color--4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner">
                                <h1 className="title theme-gradient">404!</h1>
                                <h3 className="sub-title">Page Not Found</h3>
                                <span>{t('error.404.message')}</span>
                                <div className="error-button">
                                    <Link className="btn-default" to="/">{t('error.404.action')}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default Error404Content;