import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ProgressBar } from 'react-bootstrap';

import { contentfulClient } from '../../contentfulClient.js'
import { useTranslation } from 'react-i18next';

import moment from 'moment'

function AboutTab(props) {

    const [experienceData, setExperienceData] = useState([]);
    const [awardsData, setAwardsData] = useState([]);
    const [publicationData, setPublicationData] = useState([]);

    const { tabStyle } = props

    const currentLang = props.currentLang;

    useEffect(() => {
        const fetchExperiencesData = async () => {
            const res = await contentfulClient.getEntries({
                content_type: 'experiences',
                locale: currentLang,
                order: '-fields.dateStarted'
            });
            setExperienceData(res.items);
        };
        const fetchAwardsData = async () => {
            const res = await contentfulClient.getEntries({
                content_type: 'awards',
                locale: currentLang,
                order: '-fields.dateGranted',
                limit: 4,
            });
            setAwardsData(res.items);
        };
        const fetchPublicationData = async () => {
            const res = await contentfulClient.getEntries({
                content_type: 'works',
                locale: currentLang,
                "fields.isPublication": true,
                order: '-fields.dateWorked',
                limit: 4,
            });
            setPublicationData(res.items);
        };
        fetchExperiencesData();
        fetchAwardsData();
        fetchPublicationData();

    }, [currentLang]);

    const { t } = useTranslation();

    return(
        <div>
            {/* Start Tabs Area */}
            <div className="tabs-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Tabs>
                                <TabList  className={`${tabStyle}`}>
                                    <Tab>{t('about.introTabs.skills')}</Tab>
                                    <Tab>{t('about.introTabs.experience')}</Tab>
                                    <Tab>{t('about.introTabs.education')}</Tab>
                                    <Tab>{t('about.introTabs.awards')}</Tab>
                                    <Tab>{t('about.introTabs.publications')}</Tab>
                                </TabList>

                                <TabPanel>
                                    <div className="single-tab-content">
                                        <div className={`rn-progress-bar progress-bar--1 mt_dec--10`}>

                                            <div className="single-progress">
                                                <h6 className="title">{t('about.skillTitle.programming')}</h6>
                                                <ProgressBar now={80} />
                                                <span className="label">80%</span>
                                            </div>

                                            <div className="single-progress">
                                                <h6 className="title">{t('about.skillTitle.design')}</h6>
                                                <ProgressBar now={20} />
                                                <span className="label">20%</span>
                                            </div>

                                        </div>
                                    </div>
                                </TabPanel>


                                <TabPanel>
                                    <div className="single-tab-content">
                                        <ul>
                                            {experienceData ? experienceData.map((experience, index) => {
                                                if (!experience.fields.isEducation) {
                                                    return (
                                                        <li key={index}>
                                                            <p>{experience.fields.title}<span>{experience.fields.organization ? ' - ' + experience.fields.organization.fields.name : null}</span></p>{moment(experience.fields.dateStarted).format('YYYY')} - {experience.fields.dateEnded ? moment(experience.fields.dateEnded).format('YYYY') : t("about.experienceCurrent")}
                                                        </li>
                                                    );
                                                }
                                            }) : null}
                                        </ul>
                                    </div>
                                </TabPanel>

                                <TabPanel>
                                    <div className="single-tab-content">
                                        <ul>
                                            {experienceData ? experienceData.map((experience, index) => {
                                                if (experience.fields.isEducation) {
                                                    return (
                                                        <li key={index}>
                                                            <p>{experience.fields.title}<span>{experience.fields.organization ? ' - ' + experience.fields.organization.fields.name : null}</span></p>{moment(experience.fields.dateStarted).format('YYYY')} - {experience.fields.dateEnded ? moment(experience.fields.dateEnded).format('YYYY') : t("about.experienceCurrent")}
                                                        </li>
                                                    );
                                                }
                                            }) : null}
                                        </ul>
                                    </div>
                                </TabPanel>

                                <TabPanel>
                                    <div className="single-tab-content">
                                        <ul>
                                            {awardsData ? awardsData.map((awards, index) => (
                                                <li key={index}>
                                                    <p>{awards.fields.title}<span>{awards.fields.organization ? ' - ' + awards.fields.organization.fields.name : null}</span></p>{moment(awards.fields.dateGranted).format('MMMM DD, YYYY')}
                                                </li>
                                            )) : null}
                                        </ul>
                                    </div>
                                </TabPanel>


                                <TabPanel>
                                    <div className="single-tab-content">
                                        <ul>
                                            {publicationData ? publicationData.map((works, index) => (
                                                <li key={index}>
                                                    <Link to={'/works/' + works.fields.slug}>{works.fields.title}</Link>{moment(works.fields.dateWorked).format('MMMM DD, YYYY')}
                                                </li>
                                            )) : null}
                                        </ul>
                                    </div>
                                </TabPanel>



                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Tabs Area */}
        </div>
    )
}



export default AboutTab;