import React, { Component, Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { withTranslation } from 'react-i18next';

import FeaturedWorksSlider from '../component/body/landing/featuredWorksSlider';
import InfoList from '../component/body/landing/infoList';
import BlogList from '../component/body/landing/blogList';
import WorkList from '../component/body/landing/workList';


class HomeApp extends Component{
    render(){
        const { i18n } = this.props;

        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 50) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });


        return(
            <Fragment>
                <Helmet pageTitle="Home" lang={i18n.language} />

                {/* Start Header Area  */}
                <Header/>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <FeaturedWorksSlider currentLang={i18n.language} />
                {/* End Slider Area   */}

                {/* Start Info Area   */}
                <InfoList currentLang={i18n.language} />
                {/* End Info Area */}

                {/* Start Blog Area */}
                <BlogList currentLang={i18n.language} />
                {/* End Blog Area */}

                {/* Start Portfolio Area */}
                <WorkList currentLang={i18n.language} />
                {/* End Portfolio Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </Fragment>
        )
    }
}
export default withTranslation('translation')(HomeApp);