import React, { Fragment, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { contentfulClient } from '../../../contentfulClient.js'

import Slider from "react-slick";

export const slickConfig = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 12500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    fade: true,
    easing: "fade",
    adaptiveHeight: true
};

function FeaturedWorksSlider(props) {
    const [postData, setPostData] = useState([]);

    const currentLang = props.currentLang;

    useEffect(() => {
        const fetchData = async () => {
            const res = await contentfulClient.getEntries({
                content_type: 'works',
                locale: currentLang,
                "fields.isFeatured": true,
                "fields.thumb[exists]": true,
                order: '-fields.dateWorked',
                limit: 5,
            });
            setPostData(res.items);
        };
        fetchData();
    }, [currentLang]);

    if (postData.length > 0) {
        return(
            <Fragment>

                <div className="slider-wrapper">
                    <div className="slider-activation slider-startup">
                        <Slider className="rn-slick-dot dot-light" {...slickConfig}>
                            <div data-black-overlay="8">
                                <div className='slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_overlay bg_image' style={{backgroundImage: 'url(/assets/img/portfolio_res/top_bg.jpg)'}}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className='inner text-center'>
                                                    <span className="theme-color font-700">Hello</span>
                                                    <h1 className="title theme-gradient">Kyosuke Miyamura</h1>
                                                    <p className="description">I am programmer based in Tokyo, Japan.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {postData.map((post , index) => {
                                if (post.fields.title && post.fields.thumb.fields.file && post.fields.tag.length > 0) {
                                    return (
                                        // Start Single Slider
                                        <div key={index} data-black-overlay="8">
                                            <div className='slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_overlay bg_image' style={{backgroundImage: `url(${post.fields.thumb.fields.file.url})`}}>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className='inner text-center'>
                                                                <span className="theme-color font-700">{post.fields.tag && post.fields.tag.length > 0 ? post.fields.tag[0].fields.name : 'My Featured Work'}</span>
                                                                <h1 className="title theme-gradient">{post.fields.titleShort ? post.fields.titleShort : post.fields.title}</h1>
                                                                <p className="description">{post.fields.description ? post.fields.description : ''}</p>
                                                                {post.fields.slug ? <div className="slide-btn"><Link className="btn-default btn-border btn-opacity" to={`/works/${post.fields.slug}`}>More Info</Link></div> : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        // End Single Slider
                                    )
                                } else {
                                    return null;
                                }
                            })}
                        </Slider>
                    </div>
                </div>

            </Fragment>
        )
    } else {
        return(
            <Fragment>

                <div className="slider-wrapper">
                    <div className="slider-activation slider-startup">
                        <Slider className="rn-slick-dot dot-light" {...slickConfig}>
                            <div data-black-overlay="8">
                                <div className='slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_overlay bg_image' style={{backgroundImage: 'url(/assets/img/portfolio_res/top_bg.jpg)'}}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className='inner text-center'>
                                                    <span className="theme-color font-700">Hello</span>
                                                    <h1 className="title theme-gradient">Kyosuke Miyamura</h1>
                                                    <p className="description">I am programmer based in Tokyo, Japan.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>

            </Fragment>
        )
    }
}
export default FeaturedWorksSlider;